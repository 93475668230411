<template>
	<div class="base-view">
		<BaseHeaderVue class="base-header" v-if="hasHeader" />
		<div class="base-body">
			<slot />
		</div>
	</div>
</template>

<script>
import BaseHeaderVue from "./BaseHeader.vue"
export default {
	components: {
		BaseHeaderVue
	},
	props: {
		hasHeader: {
			type: Boolean,
			default: true,
			required: false
		}
	}
}
</script>

<style lang="scss" scoped>
.base-view {
	display: flex;
	flex-direction: column;
	height: 100%;

	.base-header {
		max-height: $navigator-header-height;
	}
	.base-body {
		flex-grow: 1;
		height: 100%;
	}
}
</style>